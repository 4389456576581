export const REGIONS = [
  {
    href: "https://uat.api.manheim.com/valuations/regions/id/NA?country=US",
    id: "NA",
    name: "National"
  },
  {
    href: "https://uat.api.manheim.com/valuations/regions/id/SE?country=US",
    id: "SE",
    name: "Southeast"
  },
  {
    href: "https://uat.api.manheim.com/valuations/regions/id/NE?country=US",
    id: "NE",
    name: "Northeast"
  },
  {
    href: "https://uat.api.manheim.com/valuations/regions/id/MW?country=US",
    id: "MW",
    name: "Midwest"
  },
  {
    href: "https://uat.api.manheim.com/valuations/regions/id/SW?country=US",
    id: "SW",
    name: "Southwest"
  },
  {
    href: "https://uat.api.manheim.com/valuations/regions/id/WC?country=US",
    id: "WC",
    name: "West Coast"
  }
]


