<template>
  <b-container class="mt-3">
    <b-form v-if="!isLoadingFilters" @submit.prevent="searchVinValuation()">
      <b-row>
        <b-col md="3">
          <b-form-group label="Make">
            <vue-multiselect
              v-model="form.make"
              :options="filters.make || []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder=""
              class=""
              :preselect-first="false"
              @input="searchModel"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Model">
            <vue-multiselect
              v-model="form.model"
              :options="vehicleModels || []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder=""
              class=""
              :preselect-first="false"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <!-- year -->
          <b-form-group label="Year">
            <vue-multiselect
              v-model="form.year"
              :options="filters.year || []"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder=""
              class=""
              :preselect-first="false"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Status">
            <vue-multiselect
              v-model="form.status"
              :options="filters.status || []"
              :multiple="false"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Search status"
              class="w-96 ml-2"
              :preselect-first="false"
              @select="status = $event"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Sub Status">
            <vue-multiselect
              v-model="form.subStatus"
              :options="filters.subStatus || []"
              :multiple="false"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Search sub status"
              class="w-96 ml-2"
              :preselect-first="false"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search">
            <b-form-input
              id="vin"
              v-model="form.vin"
              type="text"
              placeholder="Enter VIN"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Region">
            <!-- select for regions -->
            <b-form-select
              id="region"
              v-model="form.region"
              :options="regions"
              placeholder="Select Region"
              label-field="name"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <!--       <b-col md="2">
          <b-form-group label="Date">
            <b-form-input
              id="date"
              v-model="form.date"
              type="date"
              placeholder="Enter Date"
            ></b-form-input>
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-center">
          <b-button type="submit" variant="primary" :disabled="isLoading">
            Submit

            <!-- loader -->
            <b-spinner
              small
              v-if="isLoading"
              label="Loading..."
              variant="white"
            ></b-spinner>
          </b-button>
          <b-button
            type="button"
            variant="success"
            class="ml-2"
            :disabled="isGenerationLoading || vehiclesValuationIsEmpty"
            @click="generateValuation()"
          >
            <span v-if="!isGenerationLoading"> Generate valuation </span>
            <span v-else> Generating valuations </span>
            <!-- loader -->
            <b-spinner
              small
              v-if="isGenerationLoading"
              label="Loading..."
              variant="white"
            ></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <VehicleValuationDetail
      v-if="vehicleValuation && !vehiclesValuationIsEmpty"
    />
  </b-container>
</template>
<script>
import {
  CLEAR_VEHICLE_VALUATION,
  CLEAR_VEHICLE_VALUATION_MODELS,
  GET_GENERATE_VALUATION,
  GET_VEHICLE_VALUATION,
  GET_VEHICLE_VALUATION_FILTERS,
  GET_VEHICLE_VALUATION_FILTERS_MODELS,
  SET_VEHICLE_VALUATION_FILTERS,
} from "./actions";
import { REGIONS } from "./regions";
import { mapState } from "vuex";
import VehicleValuationDetail from "./VehicleValuationDetail.vue";
export default {
  name: "VehicleValuationView",
  components: {
    VehicleValuationDetail,
  },
  computed: {
    ...mapState({
      vehicleValuation: (state) =>
        state.adminStore.vehicleValuation.vehicleValuations.data,
      isLoading: (state) =>
        state.adminStore.vehicleValuation.vehicleValuations.status ==
        "fetching",
      filters: (state) => state.adminStore.vehicleValuation.filters.data,
      isLoadingFilters: (state) =>
        state.adminStore.vehicleValuation.filters.status === "fetching",
      vehicleModels: (state) =>
        state.adminStore.vehicleValuation.filters.models.data,
      isGenerationLoading: (state) =>
        state.adminStore.vehicleValuation.vehicleValuationDetail.status ==
        "fetching",
    }),
    vehiclesValuationIsEmpty() {
      return this.vehicleValuation == null;
    },
  },
  data() {
    return {
      form: {
        vin: "",
        region: "",
        date: "",
        make: [],
        model: [],
        status: "",
        subStatus: "",
        year: [],
      },
      regions: REGIONS,
    };
  },

  methods: {
    searchVinValuation() {
      const payload = {
        vin: this.form.vin,
        region: this.form.region,
        date: this.form.date,
        make: this.form.make ? this.form.make.join(",") : null,
        model: this.form.model ? this.form.model.join(",") : null,
        status: this.form.status ? this.form.status.join(",") : null,
        subStatus: this.form.subStatus ? this.form.subStatus : null,
        year: this.form.year ? this.form.year.join(",") : null,
      };
      this.setFilters(payload);
      this.$store.dispatch(GET_VEHICLE_VALUATION).then((response) => {
        console.log(response);
      });
    },
    clearVehicleValuation() {
      this.$store.commit(CLEAR_VEHICLE_VALUATION);
    },
    searchModel() {
      if (!this.form.make) {
        this.$store.commit(CLEAR_VEHICLE_VALUATION_MODELS);
        this.form.make = null;
        this.form.model = null;
      } else {
        /* array to string */
        const makes = this.form.make.join(",");
        this.$store
          .dispatch(GET_VEHICLE_VALUATION_FILTERS_MODELS, { make: makes })
          .then(() => {});
      }
    },
    generateValuation() {
      const payload = {
        vin: this.form.vin,
        region: this.form.region,
        date: this.form.date,
        make: this.form.make ? this.form.make.join(",") : null,
        model: this.form.model ? this.form.model.join(",") : null,
        status: this.form.status ? this.form.status.join(",") : null,
        subStatus: this.form.subStatus ? this.form.subStatus : null,
        year: this.form.year ? this.form.year.join(",") : null,
      };
      this.setFilters(payload);
      this.$store.dispatch(GET_GENERATE_VALUATION).then((response) => {
        console.log(response);
      });
    },
    getFilters() {
      this.$store.dispatch(GET_VEHICLE_VALUATION_FILTERS).then((response) => {
        console.log(response);
      });
    },
    setFilters(payload) {
      this.$store.commit(SET_VEHICLE_VALUATION_FILTERS, {
        reset: false,
        fields: payload,
      });
    },
  },
  mounted() {
    this.getFilters();
    this.clearVehicleValuation();
  },
};
</script>
<style scoped></style>
