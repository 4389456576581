import Base from "./Base.vue";
import VehiclesTable from "./VehiclesTable.vue";
import Dashboard from "./Dashboard.vue";
/* import FxCalendar from "./components/fx-calendar/Calendar.vue"
 */import { ifRoleAuthenticated } from "@/router/navigationGuards";
const routes = [
  {
    path: "/superAdmin",
    name: "superAdmin",
    component: Base,
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]);
    },
    children: [
      {
        path: "vehicles",
        name: "superadmin.vehicles",
        component: Base,
        children: [
          {
            path: "table",
            name: "superadmin.vehicles.table",
            component: VehiclesTable,
          },
          {
            path: "dashboard",
            name: "superadmin.vehicles.dashboard",
            component: Dashboard,
          },
        ],

      },
    ],


  },
];

export default routes;
