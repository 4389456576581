import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    fxCalendarInfo: {
      status: null,
      data: null,
      error: null,
    },
    saveFxDate: {
      status: null,
      data: null,
      error: null,
    },
  }
}

const actions = {
  [constants.GET_FX_CALENDAR_INFO]: async ({ commit }) => {
    try {
      commit(constants.GET_FX_CALENDAR_INFO);
      const response = await axios.get("currency/getFx");
      commit(constants.GET_FX_CALENDAR_INFO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_FX_CALENDAR_INFO_ERROR, error);
      return error;
    }
  },
  [constants.POST_FX_DATE]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_FX_DATE);
      const response = await axios.post("currency/register", payload);
      commit(constants.POST_FX_DATE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_FX_DATE_ERROR, error);
      return error;
    }
  },
};

const mutations = {
  [constants.GET_FX_CALENDAR_INFO]: (state) => {
    state.fxCalendarInfo.status = "loading";
  },
  [constants.GET_FX_CALENDAR_INFO_SUCCESS]: (state, response) => {
    state.fxCalendarInfo.status = "success";
    state.fxCalendarInfo.data = response.data;
  },
  [constants.GET_FX_CALENDAR_INFO_ERROR]: (state, error) => {
    state.fxCalendarInfo.status = "error";
    state.fxCalendarInfo.error = error;
  },
  [constants.POST_FX_DATE]: (state) => {
    state.saveFxDate.status = "loading";
  },
  [constants.POST_FX_DATE_SUCCESS]: (state, response) => {
    state.saveFxDate.status = "success";
    state.saveFxDate.data = response.data;
  },
  [constants.POST_FX_DATE_ERROR]: (state, error) => {
    state.saveFxDate.status = "error";
    state.saveFxDate.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
