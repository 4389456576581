<template>
  <b-card body-class="p-0">
    <!--     <CardTitle :vehicle="vehicle" />


 -->
    <div class="text-center bg-primary">
      <strong>
        <i class="text-white">
          {{ vehicle.condition }}
        </i>
      </strong>
    </div>
    <VehicleCardGallery :images="vehicleImages" :typeList="typeList" />

    <b-card-text>
      <b-container class="p-3">
        <b-row>
          <b-col cols="7" class="text-left">
            <router-link v-if="vehicle.vinNumber" :to="vehiclePageURL">
              <div>
                <i> {{ cardVehicleTitle }} </i>
              </div>
            </router-link>
            <span>{{ vehicle.assetGroup }}</span>
          </b-col>

          <b-col cols="5" class="text-right">
            <span
              v-if="!companyIsPending && vehicle && vehicle.priceRetail"
              class="text-success"
            >
              <i>{{ vehicle.priceRetail | currency }}</i>
            </span>

            <span
              v-if="
                !companyIsPending &&
                vehicle &&
                vehicle.priceWholesale &&
                userIsWholesale &&
                !userIsAdmin
              "
              class="text-success"
            >
              <i>{{ vehicle.priceWholesale | currency }}</i>
            </span>
          </b-col>
          <b-col cols="7" class="text-left">
            <span v-if="vehicle.vinNumber">
              <span>{{ vehicle.miles | formatNumber }} Kms</span>
            </span>
          </b-col>
          <b-col cols="5" class="text-right">
            <span v-if="vehicle.stockNumber">
              <span>{{ vehicle.stockNumber }} </span>
            </span>

            <b-icon
              :id="'valuation-info' + vehicle.id"
              icon="info-circle"
              variant="info"
            />
            <b-tooltip
              :target="'valuation-info' + vehicle.id"
              triggers="hover focus"
              class="custom-tooltip"
              @show="getValuation(vehicle.id)"
            >
              <div>
                <b-icon
                  icon="info-circle-fill"
                  variant="primary"
                  class="mr-2"
                ></b-icon>
                <b-row>
                  <b-col md="6">Acquisition date:</b-col>
                  <b-col md="6">{{ tooltipData.date }}</b-col>
                  <b-col md="6">Cost:</b-col>
                  <b-col md="6">{{ tooltipData.cost }}</b-col>
                  <b-col md="6">$ +30:</b-col>
                  <b-col md="6">{{ tooltipData.extraCost }}</b-col>
                </b-row>
              </div>
            </b-tooltip>
          </b-col>
          <b-col cols="12" class="text-center" v-if="vehicle.vinNumber">
            <vue-barcode
              style="width: 100%"
              :value="vehicle.vinNumber"
              :options="barcodeOptions"
              class="bar-code"
            />
            <i style="font-size: 13px">
              {{ vehicle.vinNumber || vehicle.vinNumber }}
              <b-button
                variant="none"
                v-clipboard:copy="
                  vehicle.vinNumber ? vehicle.vinNumber : vehicle.vinNumber
                "
                v-clipboard:success="copyVinNumber"
              >
                <b-icon v-if="vinCopied" icon="clipboard-check" />
                <b-icon v-else icon="clipboard" />
              </b-button>

              <a
                v-if="vehicle.quoteFile"
                :href="vehicle.quoteFile"
                target="_blank"
              >
                <b-button variant="none" class="p-0">
                  <b-icon
                    icon="file-earmark"
                    class="cursor-pointer"
                    font-scale="1"
                  ></b-icon
                ></b-button>
              </a>
            </i>
          </b-col>
        </b-row>

        <b-row class="">
          <b-col cols="6" class="text-left d-flex align-items-center">
            <!--   <div
              :id="'tooltip-google-maps-' + vehicle.id"
              class="d-flex align-items-bottom"
            >
              <img
                src="@/assets/img/mapaIcon.png"
                class="img-responsive mt-1"
                width="25"
                height="25"
              />
              <small class="mt-2">
                <i>{{ vehicle.name }}</i>
              </small>
              <LocationTooltip
                :id="vehicle.id"
                :location="vehicle.exportLocations"
              />
            </div> -->
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end text-right">
            <!--  <img
              v-if="userIsAdmin"
              class="img-responsive mt-1"
              height="25"
              src="@/assets/icon/work-order-icon.png"
              v-b-tooltip.hover
              title="This vehicle has had a work order"
            /> -->
            <!-- <CRButton
              class="float-right"
              v-if="vehicle.vehicleHasConditionalReports.length > 0"
              :vehicle="vehicle"
            /> -->
          </b-col>
        </b-row>
        <div
          v-if="vehicleAcceptOffer || vehicle.status === 'Sold'"
          class="mt-2"
        >
          <img
            src="@/assets/img/TOOLATE.png"
            alt="sold-image"
            class="img-fluid"
          />
        </div>
        <div v-else>
          <b-row v-if="viewOffers" class="mt-3">
            <b-col class="mb-1">
              <b-button
                variant="primary"
                block
                @click="showVehicleOffers()"
                class="shadow"
              >
                {{ $t("viewOffers") }}
              </b-button>
            </b-col>
          </b-row>

          <b-row class="mt-2" v-else-if="!isHome && user">
            <b-col>
              <b-button
                v-if="vehicle.status !== 'Inactive' && userIsAdmin"
                variant="primary"
                block
                class="mt-1"
                @click="editVehicle(vehicle.vinNumber)"
              >
                <b-icon icon="pencil" />
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="
              !isHome &&
              !isActiveOffer &&
              !isExpiredOffer &&
              (userHasOffer || vehicle.lastOffer) &&
              userHasCompany
            "
            class="mt-3"
          >
            <b-col>
              <b-button
                variant="primary"
                block
                size="sm"
                @click="changeOfferTab(vehicle.id)"
              >
                <h5 class="mt-1">
                  {{ $t("currentOffer") }}:
                  <span v-if="vehicle.lastOffer">
                    {{ vehicle.lastOffer.offerPrice | currency }}
                  </span>
                  <span v-else-if="userHasOffer">
                    {{ currentOffer | currency }}
                  </span>
                </h5>
              </b-button>
            </b-col>
          </b-row>

          <MakeAnOffer
            class="mt-3"
            v-if="
              !isHome &&
              !userHasOffer &&
              !vehicle.lastOffer &&
              !isExpiredOffer &&
              !isActiveOffer &&
              !userIsAdmin &&
              !userIsAgent &&
              !userIsRetail
            "
            :vehicleId="this.vehicle.id"
            :price="this.vehicle.priceRetail"
            :userPending="!userHasCompany"
            @setUserHasOffer="setUserHasOffer($event)"
            :vehicle="vehicle"
          />
          <b-button
            class="mt-2"
            variant="primary"
            block
            @click="copyToClipboard(generateUrlToCopy(item))"
          >
            {{ $t("copyLink") }}
          </b-button>
        </div>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import VehicleCardGallery from "./VehicleCardGallery.vue";
/* import LocationTooltip from "../components/locationTooltips/LocationTooltip.vue";
 */ import MakeAnOffer from "../components/MakeAnOffer";

import { SET_VEHICLES_FILTERS, MARK_NOTIFICATION_AS_READ } from "../actions";
import {
  SET_VEHICLE_DETAILS_SIDEBAR,
  SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR,
} from "../components/actions";
import {
  SHOW_OFFERS_SIDEBAR,
  SHOW_OFFER_SIDEBAR,
  SET_OFFER_SIDEBAR,
  GET_OFFERS_BY_VEHICLE,
} from "@/modules/admin/vehicles/offers/actions";
import { GET_NOTIFICATIONS } from "@/modules/admin/notifications/actions";
import { listVehiclesMixin } from "../mixins";
import { generalMixin } from "@/modules/mixin";
import IMAGES_PARTS from "./images-parts";
import { mapState } from "vuex";
export default {
  name: "VehicleCard",
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    viewOffers: {
      type: Boolean,
      default: false,
    },
    isActiveOffer: {
      type: Boolean,
      default: false,
    },
    isExpiredOffer: {
      type: Boolean,
      default: false,
    },
    typeList: {
      type: String,
      default: "list",
    },
  },
  components: {
    VehicleCardGallery,
    MakeAnOffer,
  },
  mixins: [listVehiclesMixin, generalMixin],
  data() {
    return {
      cardImages: [],
      exterior: [],
      exteriorThumbnail: [],
      interior: [],
      interiorThumbnail: [],
      barcodeOptions: {
        format: "CODE39",
        with: 10,
        height: 60,
        displayValue: false,
        marginTop: 10,
      },
      userHasOffer: false,
      currentOffer: null,
      vinCopied: null,
      vehicleImages: [],
      BASE_URL: "https://proleasing.nyc3.cdn.digitaloceanspaces.com/Prod",
      isLoadingImagesFromSpace: false,
      tooltipData: {
        date: "Loading...",
        cost: "Loading...",
        extraCost: "Loading...",
      },
    };
  },

  computed: {
    ...mapState({
      vehicleValuation: (state) =>
        state.adminStore.vehicleValuation.vehicleValuations.data,
    }),
    cardVehicleTitle() {
      return (
        this.vehicle?.year +
        " " +
        this.vehicle?.make +
        " " +
        this.vehicle?.model +
        " " +
        this.vehicle?.color
      );
    },
    vehiclePageURL() {
      return (
        "/vehicle/" +
        this.vehicle.vinNumber +
        "-" +
        this.vehicle.make +
        "-" +
        this.vehicle.model
      );
    },

    userOffer() {
      return {};
    },
    lastOfferUser() {
      return this.vehicle.lastOfferUser;
    },
    lastOfferAdmin() {
      return this.vehicle.lastOfferAdmin;
    },
    isHome() {
      return this.$route.name === "home" ? true : false;
    },
    vehicleDetailsSideBar() {
      return this.$store.state.adminStore.vehicles.listStore.componentsStore
        .vehicleDetailsSideBar;
    },
    offerSideBar() {
      return this.$store.state.adminStore.vehicles.offersStore.offerSideBar;
    },
    vehicleAcceptOffer() {
      return this.$store.state.adminStore.vehicles.offersStore.vehiclesSold.vehicles.find(
        (vehicle) => vehicle.vehicleId === this.vehicle.id
      );
    },

    costCalculated() {
      /*     let cost = this.vehicle.vehicleCostDetail.cost;
      if (this.vehicle.vehicleCostDetail.fx) {
        if (this.vehicle.vehicleCostDetail.cost === 0) {
          return cost;
        } else {
          return (cost + 2500) / this.vehicle.vehicleCostDetail.fx;
        }
      } */
      return this.vehicle.cost;
    },
    baseUrl() {
      return `${this.BASE_URL}/${this.vehicle?.year}/${this.vehicle?.make}/${this.vehicle?.model2}/${this.vehicle?.color}`;
    },
  },
  mounted() {
    this.setImages();
    setTimeout(() => {
      let figure = document.querySelectorAll(".lingalleryContainer figure");
      if (!figure) return;
      figure.forEach((f) => {
        f.style.cursor = "-webkit-zoom-in";
      });
    }, 1000);
    if (this.vehicle.vehicleHasParts.length > 0) {
      this.formatManualImages();
    } else {
      this.formatImagesFromSpace();
    }
  },
  methods: {
    formatImages(images) {
      return images.map((image) => {
        return {
          id: image.photo,
          src: image.photo,
          largeViewSrc: image.photo,
          thumbnail: image.photoThumbnail || image.photo,
        };
      });
    },
    setImages() {
      this.vehicle?.vehicleHasParts?.forEach((p) => {
        /*         this.exterior.push(p.photo);
         */
        this.exterior.push({
          photo: p.photo,
          photoThumbnail: p.photoThumbnail,
        });
        this.exteriorThumbnail.push(p.photoThumbnail);
      });
      if (this.vehicle?.vehicleExternalImages?.length > 0) {
        this.exterior = [];
        this.vehicle?.vehicleExternalImages?.forEach((p) => {
          /*           this.exterior.push(p.photo);
           */
          this.exterior.push({
            photo: p.photo,
            photoThumbnail: p.photoThumbnail,
          });
          this.exteriorThumbnail.push(p.photoThumbnail);
        });
      }
      if (this.vehicle?.damagePhotos?.length > 0) {
        this.damagesDots = this.vehicle.damagePhotos;
      }
      this.vehicle?.damagePhotos?.forEach((p) => {
        this.damages.push(p.damagePhoto);
      });
    },
    openCRPageModal() {
      window.open(this.vehicle?.vehicleHasConditionalReports[0]?.url, "_blank");
    },
    markNoticationAsRead() {
      const payload = {
        id: this.vehicle.vehicleId,
      };
      this.$store.commit(MARK_NOTIFICATION_AS_READ, {});

      this.$store.dispatch(MARK_NOTIFICATION_AS_READ, payload).then(() => {
        this.$store.dispatch(GET_NOTIFICATIONS);
      });
    },
    copyVinNumber(vin) {
      this.vinCopied = vin;
    },
    editVehicle(vin) {
      /*    this.$router.push({
        name: this.userIsAdmin
          ? "admin.vehicles.registry.general"
          : "agent.vehicles.edit.general",
        params: { vin: vin },
      }); */
      this.$store.commit("SET_IS_SHOWING_VIN_INFORMATION_SIDEBAR", {
        status: true,
        data: vin,
      });
    },
    showVehicleOffers() {
      this.$store.commit(SHOW_OFFER_SIDEBAR, false);
      this.$store.commit(SHOW_OFFERS_SIDEBAR, false);
      this.getVehicleOffers();
      setTimeout(() => {
        this.$store.commit(SHOW_OFFER_SIDEBAR, true);
      }, 1000);
    },
    getVehicleOffers() {
      this.$store
        .dispatch(GET_OFFERS_BY_VEHICLE, this.vehicle.vehicleId)
        .then(() => {
          // SET VEHICLE DATA TO SHOW IN SIDEBAR
          this.vehicle.isActiveOffer = this.isActiveOffer;
          this.$store.commit(SET_OFFER_SIDEBAR, this.vehicle);

          this.markNoticationAsRead();
        })

        .catch(() => {
          this.toastMessage(
            "The vehicle offers could not be obtained",
            "Warning",
            "warning"
          );
        });
    },
    changeOfferTab(vehicleId) {
      this.$parent.$emit("resetFilters");
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: { vehicleId } });
      this.$emit("changeTabNumber");
    },

    openVehicleDetails() {
      let sideBar = this.vehicleDetailsSideBar;
      if (!sideBar.data) {
        this.$store.commit(SET_VEHICLE_DETAILS_SIDEBAR, this.vehicle);
        this.$store.commit(SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR, true);
      } else {
        if (sideBar.data.id !== this.vehicle.id) {
          this.$store.commit(SET_VEHICLE_DETAILS_SIDEBAR, this.vehicle);
          this.$store.commit(SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR, false);
          setTimeout(() => {
            this.$store.commit(SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR, true);
          }, 400);
        } else if (!sideBar.isShowing) {
          this.$store.commit(SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR, true);
        }
      }
    },
    setUserHasOffer(offer) {
      this.userHasOffer = true;
      this.currentOffer = offer;
    },
    formatImagesFromSpace() {
      this.isLoadingImagesFromSpace = true;
      let images = [];
      let baseUrl = this.baseUrl;
      let promises = [];

      IMAGES_PARTS.forEach((part) => {
        let img = new Image();
        let promise = new Promise((resolve) => {
          const setImage = (extension) => {
            img.src = `${baseUrl}/${part}.${extension}`;
            img.onload = () => {
              images.push({
                id: `${baseUrl}/${part}.${extension}`,
                src: `${baseUrl}/${part}.${extension}`,
                largeViewSrc: `${baseUrl}/${part}.${extension}`,
                thumbnail: `${baseUrl}/${part}.${extension}`,
              });
              resolve();
            };
            img.onerror = () => {
              if (extension === "jpeg") {
                setImage("jpg"); // Intenta con .jpg si falla .jpeg
              } else {
                resolve();
              }
            };
          };

          setImage("jpeg"); // Comienza intentando cargar .jpeg
        });

        promises.push(promise);
      });

      Promise.all(promises).then(() => {
        if (images.length === 0) {
          images.push({
            id: "proleasing-logo.png",
            src: "proleasing-logo.png",
            largeViewSrc: "proleasing-logo.png",
            thumbnail: "proleasing-logo.png",
          });
        }

        if (images.find((img) => img.src !== "proleasing-logo.png")) {
          this.vehicleImages = images.filter(
            (img) => img.src !== "proleasing-logo.png"
          );
        } else {
          this.vehicleImages = images;
        }

        this.isLoadingImagesFromSpace = false;
      });
    },

    generateUrlToCopy(vehicle) {
      return (
        window.location.hostname +
        "/vehicle/" +
        vehicle.vinNumber +
        "-" +
        vehicle.make +
        "-" +
        vehicle.model
      );
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.toastMessage("Link copied to clipboard", "Success", "success");
        },
        () => {
          this.toastMessage("Link could not be copied", "Warning", "warning");
        }
      );
    },
    formatManualImages() {
      this.vehicleImages = this.vehicle.vehicleHasParts.map((part) => {
        return {
          id: part.id,
          src: part.photo,
          largeViewSrc: part.photo,
          thumbnail: part.photoThumbnail,
        };
      });
    },

    async getValuation(vehicleId) {
      try {
        this.$store.commit("SET_VEHICLE_VALUATION_FILTERS", {
          reset: false,
          fields: {
            id: vehicleId,
          },
        });

        const response = await this.$store.dispatch("GET_VEHICLE_VALUATION");

        if (response && response.data && response.data.length > 0) {
          const data = response.data[0];

          this.tooltipData = {
            date: data.acquisitionDate || "N/A",
            cost:
              this.formatCurrency(data.costTodayBelow ?? 0) +
              " - " +
              this.formatCurrency(data.costTodayAbove ?? 0),
            extraCost:
              this.formatCurrency(data.forecastedCost30DaysBelow ?? 0) +
              " - " +
              this.formatCurrency(data.forecastedCost30DaysAbove ?? 0),
          };
        } else {
          this.tooltipData = {
            date: "N/A",
            cost: "N/A",
            extraCost: "N/A",
          };
        }
      } catch (error) {
        console.error("Error en getValuation:", error);
        this.tooltipData = {
          date: "Error",
          cost: "Error",
          extraCost: "Error",
        };
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "CAD",
      }).format(value);
    },
  },
};
</script>

<style>
.tooltip-inner {
  max-width: 450px !important;
  text-align: left !important;
  padding: 10px;
}
</style>
