var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"mt-3",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"float-right",attrs:{"disabled":_vm.vehicleValuationXLSIsLoading,"variant":"outline-success"},on:{"click":_vm.downloadVehicleValuation}},[_vm._v(" Download XLS "),_c('b-icon',{attrs:{"icon":"file-spreadsheet"}}),(_vm.vehicleValuationXLSIsLoading)?_c('b-spinner',{attrs:{"small":"","label":"Loading..."}}):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"pt-4"},[_c('b-table',{attrs:{"show-empty":"","bordered":"","small":"","stacked":"md","head-variant":"light","items":_vm.vehicleValuation.data ? _vm.vehicleValuation.data : [],"fields":_vm.fields,"busy":_vm.vehicleValuationStatus.status === 'fetching'},scopedSlots:_vm._u([{key:"cell(acquisitionDateCost)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.acquisitionCostBelow))+" - "+_vm._s(_vm._f("currency")(item.acquisitionCostAbove))+" ")]}},{key:"cell(acquisitionDateCost30)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.acquisitionCost3MonthBelow))+" - "+_vm._s(_vm._f("currency")(item.acquisitionCost3MonthAbove))+" ")]}},{key:"cell(acquisitionDateCost60)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.acquisitionCost6MonthBelow))+" - "+_vm._s(_vm._f("currency")(item.acquisitionCost6MonthAbove))+" ")]}},{key:"cell(acquisitionDateCost90)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.acquisitionCost9MonthBelow))+" - "+_vm._s(_vm._f("currency")(item.acquisitionCost9MonthAbove))+" ")]}},{key:"cell(acquisitionDateCost1Year)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.acquisitionCost1YearBelow))+" - "+_vm._s(_vm._f("currency")(item.acquisitionCost1YearAbove))+" ")]}},{key:"cell(todayCost)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.costTodayBelow))+" - "+_vm._s(_vm._f("currency")(item.costTodayAbove))+" ")]}},{key:"cell(todayCost30)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.forecastedCost30DaysBelow))+" - "+_vm._s(_vm._f("currency")(item.forecastedCost30DaysAbove))+" ")]}},{key:"cell(todayCost90)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.forecastedCost90DaysBelow))+" - "+_vm._s(_vm._f("currency")(item.forecastedCost90DaysAbove))+" ")]}}])}),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-pagination',{staticClass:"mb-2",attrs:{"value":_vm.vehicleValuation ? _vm.vehicleValuation.currentPage : 1,"total-rows":_vm.vehicleValuation ? _vm.vehicleValuation.total : 0,"per-page":_vm.vehicleValuation ? _vm.vehicleValuation.perPage : 0},on:{"change":function($event){return _vm.changePage($event)}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }