/** @format */

const CONFIG = {
  ENV: process.env.NODE_ENV,
  API_URL: process.env.VUE_APP_API_URL,
  FRONTEND_URL: process.env.VUE_APP_FRONTEND_URL,


  isDev() {
    return CONFIG.ENV === "development";
  },
  isProd() {
    return CONFIG.ENV === "production";
  },
  isStaging() {
    return CONFIG.ENV === "staging";
  },
  isLocal() {
    return CONFIG.ENV === "local";
  },
  baseURL() {
    return CONFIG.API_URL;
  },
  frontEndURL() {
    return CONFIG.FRONTEND_URL;
  },
};

export default CONFIG;
