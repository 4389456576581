export const GET_VEHICLE_VALUATION = 'GET_VEHICLE_VALUATION';
export const GET_VEHICLE_VALUATION_SUCCESS = 'GET_VEHICLE_VALUATION_SUCCESS';
export const GET_VEHICLE_VALUATION_ERROR = 'GET_VEHICLE_VALUATION_ERROR';

export const GET_VEHICLE_VALUATION_FILTERS = 'GET_VEHICLE_VALUATION_FILTERS';
export const GET_VEHICLE_VALUATION_FILTERS_SUCCESS = 'GET_VEHICLE_VALUATION_FILTERS_SUCCESS';
export const GET_VEHICLE_VALUATION_FILTERS_ERROR = 'GET_VEHICLE_VALUATION_FILTERS_ERROR';


export const GET_VEHICLE_VALUATION_FILTERS_MODELS = 'GET_VEHICLE_VALUATION_FILTERS_MODELS';
export const GET_VEHICLE_VALUATION_FILTERS_MODELS_SUCCESS = 'GET_VEHICLE_VALUATION_FILTERS_MODELS_SUCCESS';
export const GET_VEHICLE_VALUATION_FILTERS_MODELS_ERROR = 'GET_VEHICLE_VALUATION_FILTERS_MODELS_ERROR';
export const CLEAR_VEHICLE_VALUATION_MODELS = 'CLEAR_VEHICLE_VALUATION_MODELS';

export const GET_GENERATE_VALUATION = 'GET_GENERATE_VALUATION';
export const GET_GENERATE_VALUATION_SUCCESS = 'GET_GENERATE_VALUATION_SUCCESS';
export const GET_GENERATE_VALUATION_ERROR = 'GET_GENERATE_VALUATION_ERROR';

export const SET_VEHICLE_VALUATION_FILTERS = 'SET_VEHICLE_VALUATION_FILTERS';

export const GET_VEHICLE_VALUATION_XLS = 'GET_VEHICLE_VALUATION_XLS';
export const GET_VEHICLE_VALUATION_XLS_SUCCESS = 'GET_VEHICLE_VALUATION_XLS_SUCCESS';
export const GET_VEHICLE_VALUATION_XLS_ERROR = 'GET_VEHICLE_VALUATION_XLS_ERROR';

export const CLEAR_VEHICLE_VALUATION = 'CLEAR_VEHICLE_VALUATION';