import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import fxCalendar from "./fx-calendar/store"
function getDefaultState() {
  return {
    saveCheck: {
      status: null,
      data: null,
      error: null,
    },
    checks: {
      status: null,
      data: [],
      error: null,
      filters: {
        vehicle: null,
        resourcesPerPage: 10,
        page: 1,
      },
    },
    saveConditionalReport: {
      status: null,
      data: null,
      error: null,
    },
    conditionalReports: {
      status: null,
      data: [],
      error: null,
      filters: {
        vehicle: null,
        resourcesPerPage: 10,
        page: 1,
      },
    },
    saveNote: {
      status: null,
      data: null,
      error: null,
    },
    notes: {
      status: null,
      data: [],
      error: null,
      filters: {
        vehicle: null,
        resourcesPerPage: 10,
        page: 1,
      },
    },
  };
}

const actions = {
  [constants.POST_VEHICLE_CHECK]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_VEHICLE_CHECK);
      const response = await axios.post("vehicle/check", payload);
      commit(constants.POST_VEHICLE_CHECK_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_VEHICLE_CHECK_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_CHECKS]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLE_CHECKS);
      const payload = Object.entries(state.checks.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("vehicle/check", { params: payload });
      commit(constants.GET_VEHICLE_CHECKS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_CHECKS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_VEHICLE_CONDITIONAL_REPORT]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_VEHICLE_CONDITIONAL_REPORT);
      const response = await axios.post("vehicle/conditional-report", payload);
      commit(constants.POST_VEHICLE_CONDITIONAL_REPORT_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_VEHICLE_CONDITIONAL_REPORT_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_CONDITIONAL_REPORTS]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLE_CONDITIONAL_REPORTS);
      const payload = Object.entries(state.conditionalReports.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("vehicle/conditional-report", {
        params: payload,
      });
      commit(constants.GET_VEHICLE_CONDITIONAL_REPORTS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_CONDITIONAL_REPORTS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_VEHICLE_NOTE]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_VEHICLE_NOTE);
      const response = await axios.post("vehicle/note", payload);
      commit(constants.POST_VEHICLE_NOTE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_VEHICLE_NOTE_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_NOTES]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLE_NOTES);
      const payload = Object.entries(state.notes.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get("vehicle/note", { params: payload });
      commit(constants.GET_VEHICLE_NOTES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_NOTES_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.POST_VEHICLE_CHECK]: (state) => {
    state.saveCheck.status = "fetching";
    state.saveCheck.error = null;
  },
  [constants.POST_VEHICLE_CHECK_SUCCESS]: (state, data) => {
    state.saveCheck.status = "success";
    state.saveCheck.data = data;
    state.saveCheck.error = null;
  },
  [constants.POST_VEHICLE_CHECK_ERROR]: (state, error) => {
    state.saveCheck.status = "error";
    state.saveCheck.data = [];
    state.saveCheck.error = error;
  },
  [constants.SET_VEHICLES_CHECKS_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.checks.filters = {
        vehicle: null,
        resourcesPerPage: 10,
        page: 1,
      };
    }
    if (filters.fields) {
      state.checks.filters = {
        ...state.checks.filters,
        ...filters.fields,
      };
    }
  },
  [constants.GET_VEHICLE_CHECKS]: (state) => {
    state.checks.status = "fetching";
    state.checks.error = null;
  },
  [constants.GET_VEHICLE_CHECKS_SUCCESS]: (state, data) => {
    state.checks.status = "success";
    state.checks.data = data;
    state.checks.error = null;
  },
  [constants.GET_VEHICLE_CHECKS_ERROR]: (state, error) => {
    state.checks.status = "error";
    state.checks.data = [];
    state.checks.error = error;
  },
  [constants.POST_VEHICLE_CONDITIONAL_REPORT]: (state) => {
    state.saveConditionalReport.status = "fetching";
    state.saveConditionalReport.error = null;
  },
  [constants.POST_VEHICLE_CONDITIONAL_REPORT_SUCCESS]: (state, data) => {
    state.saveConditionalReport.status = "success";
    state.saveConditionalReport.data = data;
    state.saveConditionalReport.error = null;
  },
  [constants.POST_VEHICLE_CONDITIONAL_REPORT_ERROR]: (state, error) => {
    state.saveConditionalReport.status = "error";
    state.saveConditionalReport.data = [];
    state.saveConditionalReport.error = error;
  },
  [constants.SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.conditionalReports.filters = {
        vehicle: null,
        resourcesPerPage: 10,
        page: 1,
      };
    }
    if (filters.fields) {
      state.conditionalReports.filters = {
        ...state.conditionalReports.filters,
        ...filters.fields,
      };
    }
  },
  [constants.GET_VEHICLE_CONDITIONAL_REPORTS]: (state) => {
    state.conditionalReports.status = "fetching";
    state.conditionalReports.error = null;
  },
  [constants.GET_VEHICLE_CONDITIONAL_REPORTS_SUCCESS]: (state, data) => {
    state.conditionalReports.status = "success";
    state.conditionalReports.data = data;
    state.conditionalReports.error = null;
  },
  [constants.GET_VEHICLE_CONDITIONAL_REPORTS_ERROR]: (state, error) => {
    state.conditionalReports.status = "error";
    state.conditionalReports.data = [];
    state.conditionalReports.error = error;
  },
  [constants.POST_VEHICLE_NOTE]: (state) => {
    state.saveNote.status = "fetching";
    state.saveNote.error = null;
  },
  [constants.POST_VEHICLE_NOTE_SUCCESS]: (state, data) => {
    state.saveNote.status = "success";
    state.saveNote.data = data;
    state.saveNote.error = null;
  },
  [constants.POST_VEHICLE_NOTE_ERROR]: (state, error) => {
    state.saveNote.status = "error";
    state.saveNote.data = [];
    state.saveNote.error = error;
  },
  [constants.SET_VEHICLE_NOTES_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.notes.filters = {
        vehicle: null,
        resourcesPerPage: 10,
        page: 1,
      };
    }
    if (filters.fields) {
      state.notes.filters = {
        ...state.notes.filters,
        ...filters.fields,
      };
    }
  },
  [constants.GET_VEHICLE_NOTES]: (state) => {
    state.notes.status = "fetching";
    state.notes.error = null;
  },
  [constants.GET_VEHICLE_NOTES_SUCCESS]: (state, data) => {
    state.notes.status = "success";
    state.notes.data = data;
    state.notes.error = null;
  },
  [constants.GET_VEHICLE_NOTES_ERROR]: (state, error) => {
    state.notes.status = "error";
    state.notes.data = [];
    state.notes.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    fxCalendar
  }
};
