<template>
  <b-container>
    <div class="mt-3">
      <FullCalendar :options="calendarOptions" />
    </div>
    <b-modal
      centered
      no-close-on-backdrop
      scrollable
      id="fx-calendar-modal"
      ref="fx-calendar-modal"
      title="Create FX"
      hide-footer
      @hidden="resetForm()"
    >
      <FxForm :date="form.date" :fx="form.fx" :closeModal="closeModal" />
    </b-modal>
    <b-modal
      id="loading-modal"
      ref="loading-modal"
      title="loading"
      hide-footer
      hide-header
    >
      <div class="d-flex justify-content-center">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import FxForm from "./FxForm";
import { GET_FX_CALENDAR_INFO, POST_FX_DATE } from "./actions";

export default {
  name: "Calendar",
  components: {
    FullCalendar,
    FxForm,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, multiMonthPlugin],
        initialView: "multiMonthYear",
        weekends: true,
        editable: true,
        selectable: true,
        events: [],
        dragStart: this.handleDragStart,
        eventClick: this.handleDateClick,
        select: this.handleDateSelect,
        eventChange: this.handleChange,
        eventRemove: this.handleEventRemove,
      },
      form: {
        date: "",
      },
    };
  },
  methods: {
    handleDateClick(arg) {
      console.log(arg);
      arg.event._instance.range.start.setDate(
        arg.event._instance.range.start.getDate() + 1
      );
      const date = this.formatDate(arg.event._instance.range.start);

      this.openFxModal(date, arg.event._def.title);
    },

    handleDateSelect(arg) {
      console.log(arg);
      this.openFxModal(arg.startStr, arg.title);
    },
    async handleChange(arg) {
      console.log(arg);
      const dates = await this.getRangeDatesArray(
        arg.event._instance.range.start,
        arg.event._instance.range.end
      );
      this.saveFxDateMultiDay(arg.event._def.title, dates);
    },
    saveFxDateMultiDay(fx, dates) {
      const steps = dates.length;
      this.$refs["loading-modal"].show();
      dates.forEach((date, index) => {
        this.$store
          .dispatch(POST_FX_DATE, {
            date,
            fx,
          })
          .then(() => {
            if (index === steps - 1) {
              this.getFXCalendar();
              this.$refs["loading-modal"].hide();
            }
          });
      });
    },
    getRangeDatesArray(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      while (currentDate <= endDate) {
        dates.push(this.formatDate(currentDate));
        currentDate = new Date(currentDate);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      dates.shift();
      return dates;
    },
    openFxModal(date, fx) {
      this.form.date = date;
      this.form.fx = fx;
      this.$bvModal.show("fx-calendar-modal");
    },
    resetForm() {
      this.form = {
        date: "",
      };
    },
    closeModal() {
      this.$bvModal.hide("fx-calendar-modal");
      this.getFXCalendar();
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(dateString.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(dateString.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    handleEventRemove(arg) {
      console.log(arg);
    },
    getFXCalendar() {
      this.$store.dispatch(GET_FX_CALENDAR_INFO).then((response) => {
        this.calendarOptions.events = response.data.map((item) => {
          return {
            title: item.fx,
            start: item.date,
            id: item.id,
          };
        });
      });
    },
  },
  mounted() {
    this.getFXCalendar();
  },
};
</script>
<style scoped></style>
