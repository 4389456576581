<template>
  <div>
    <!-- download button aligned at the end -->
    <b-container fluid class="mt-3">
      <b-row>
        <b-col>
          <b-button
            :disabled="vehicleValuationXLSIsLoading"
            variant="outline-success"
            @click="downloadVehicleValuation"
            class="float-right"
          >
            Download XLS <b-icon icon="file-spreadsheet" />
            <!-- loader -->
            <b-spinner
              v-if="vehicleValuationXLSIsLoading"
              small
              label="Loading..."
            />
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <div class="pt-4">
      <b-table
        show-empty
        bordered
        small
        stacked="md"
        head-variant="light"
        :items="vehicleValuation.data ? vehicleValuation.data : []"
        :fields="fields"
        :busy="vehicleValuationStatus.status === 'fetching'"
      >
        <template v-slot:cell(acquisitionDateCost)="{ item }">
          {{ item.acquisitionCostBelow | currency }} -
          {{ item.acquisitionCostAbove | currency }}
        </template>
        <template v-slot:cell(acquisitionDateCost30)="{ item }">
          {{ item.acquisitionCost3MonthBelow | currency }} -
          {{ item.acquisitionCost3MonthAbove | currency }}
        </template>
        <template v-slot:cell(acquisitionDateCost60)="{ item }">
          {{ item.acquisitionCost6MonthBelow | currency }} -
          {{ item.acquisitionCost6MonthAbove | currency }}
        </template>
        <template v-slot:cell(acquisitionDateCost90)="{ item }">
          {{ item.acquisitionCost9MonthBelow | currency }} -
          {{ item.acquisitionCost9MonthAbove | currency }}
        </template>
        <template v-slot:cell(acquisitionDateCost1Year)="{ item }">
          {{ item.acquisitionCost1YearBelow | currency }} -
          {{ item.acquisitionCost1YearAbove | currency }}
        </template>
        <template v-slot:cell(todayCost)="{ item }">
          {{ item.costTodayBelow | currency }} -
          {{ item.costTodayAbove | currency }}
        </template>
        <template v-slot:cell(todayCost30)="{ item }">
          {{ item.forecastedCost30DaysBelow | currency }} -
          {{ item.forecastedCost30DaysAbove | currency }}
        </template>
        <template v-slot:cell(todayCost90)="{ item }">
          {{ item.forecastedCost90DaysBelow | currency }} -
          {{ item.forecastedCost90DaysAbove | currency }}
        </template>
      </b-table>
      <b-row>
        <b-col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="d-flex justify-content-end"
        >
          <b-pagination
            :value="vehicleValuation ? vehicleValuation.currentPage : 1"
            :total-rows="vehicleValuation ? vehicleValuation.total : 0"
            :per-page="vehicleValuation ? vehicleValuation.perPage : 0"
            class="mb-2"
            @change="changePage($event)"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  GET_VEHICLE_VALUATION,
  GET_VEHICLE_VALUATION_XLS,
  SET_VEHICLE_VALUATION_FILTERS,
} from "./actions";
export default {
  name: "VehicleValuationDetail",
  computed: {
    ...mapState({
      vehicleValuation: (state) =>
        state.adminStore.vehicleValuation.vehicleValuations.data,
      vehicleValuationStatus: (state) =>
        state.adminStore.vehicleValuation.vehicleValuations.status,
      vehicleValuationXLSIsLoading: (state) =>
        state.adminStore.vehicleValuation.vehicleValuationXLS.status ===
        "fetching",
    }),
  },
  data() {
    return {
      fields: [
        { key: "vin", label: "VIN" },
        { key: "fx", label: "FX" },
        { key: "acquisitionDate", label: "Acquisition date" },
        { key: "region", label: "Region" },
        { key: "acquisitionDateCost", label: "$ AD" },
        { key: "acquisitionDateCost30", label: "$ AD + 3m" },
        { key: "acquisitionDateCost60", label: "$ AD + 6m" },
        { key: "acquisitionDateCost90", label: "$ AD + 9m" },
        { key: "acquisitionDateCost1Year", label: "$ AD + 1y" },
        { key: "todayCost", label: "$ Today" },
        { key: "todayCost30", label: "$ Today + 30" },
        { key: "todayCost90", label: "$ Today + 90" },
      ],
    };
  },
  methods: {
    downloadVehicleValuation() {
      this.$store.dispatch(GET_VEHICLE_VALUATION_XLS, {}).then((response) => {
        const blob = new Blob([response], {
          type: "application/vnd.ms-excel",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          this.formatFileName("Manheim Valuation") + ".xls" || "ficher.xls";
        link.click();
      });
    },
    formatFileName(type) {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${type}-${year}-${month}-${day}`;
    },

    changePage(page) {
      this.$store.commit(SET_VEHICLE_VALUATION_FILTERS, {
        reset: false,
        fields: {
          page,
        },
      });
      this.$store.dispatch(GET_VEHICLE_VALUATION).then((response) => {
        console.log(response);
      });
    },
  },
};
</script>
