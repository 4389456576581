import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    vehicleValuations: {
      status: null,
      data: [],
      error: null,
      filters: {
        make: null,
        model: null,
        year: null,
        status: null,
        subStatus: null,
        vin: null,
        region: null,
        page: 1,
        id: null,
      }
    },
    vehicleValuationDetail: {
      status: null,
      data: null,
      error: null,
    },
    filters: {
      status: null,
      data: null,
      error: null,
      models: {
        status: null,
        data: [],
        error: null,
      },
    },

    vehicleValuationXLS: {
      status: null,
      data: null,
      error: null,
    },

  };
}

const actions = {
  [constants.GET_VEHICLE_VALUATION]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLE_VALUATION);
      const payload = Object.entries(state.vehicleValuations.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get(`getVehicleValuations`, { params: { ...payload } });
      commit(constants.GET_VEHICLE_VALUATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_VALUATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_VALUATION_FILTERS]: async ({ commit }, params) => {
    try {
      commit(constants.GET_VEHICLE_VALUATION_FILTERS);
      const response = await axios.get(`vehicle/getFiltersCanam`, {
        params: params
      });
      commit(constants.GET_VEHICLE_VALUATION_FILTERS_SUCCESS, response);
      return response;
    } catch (error) {
      console.log("error", error);
      commit(constants.GET_VEHICLE_VALUATION_FILTERS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_VALUATION_FILTERS_MODELS]: async ({ commit }, params) => {
    try {
      commit(constants.GET_VEHICLE_VALUATION_FILTERS_MODELS);
      const response = await axios.get(`vehicle/getFiltersCanam`, {
        params: params
      });
      console.log("modesl", response);
      commit(constants.GET_VEHICLE_VALUATION_FILTERS_MODELS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_VALUATION_FILTERS_MODELS_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_GENERATE_VALUATION]: async ({ commit, state }) => {
    try {
      commit(constants.GET_GENERATE_VALUATION);
      const payload = Object.entries(state.vehicleValuations.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get(`generateValuation`, {
        params: payload
      });
      commit(constants.GET_GENERATE_VALUATION_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_GENERATE_VALUATION_ERROR, error);
      throw error.response;
    }
  },
  [constants.GET_VEHICLE_VALUATION_XLS]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLE_VALUATION_XLS);
      const payload = Object.entries(state.vehicleValuations.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get(`createManheimValuationExcel`, {
        params: payload,
        responseType: 'blob'
      });
      commit(constants.GET_VEHICLE_VALUATION_XLS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_VALUATION_XLS_ERROR, error);
      throw error.response;
    }
  }

};

const mutations = {
  [constants.GET_VEHICLE_VALUATION]: (state) => {
    state.vehicleValuations.status = "fetching";
    state.vehicleValuations.data = null;
    state.vehicleValuations.error = null;
  },
  [constants.GET_VEHICLE_VALUATION_SUCCESS]: (state, response) => {
    state.vehicleValuations.status = "success";
    state.vehicleValuations.data = response;
    state.vehicleValuations.error = null
  },
  [constants.GET_VEHICLE_VALUATION_ERROR]: (state, error) => {
    state.vehicleValuations.status = "error";
    state.vehicleValuations.data = null;
    state.vehicleValuations.error = error;
  },
  [constants.GET_VEHICLE_VALUATION_FILTERS]: (state) => {
    state.filters.status = "fetching";
    state.filters.data = null;
    state.filters.error = null;
  },
  [constants.GET_VEHICLE_VALUATION_FILTERS_SUCCESS]: (state, response) => {
    state.filters.status = "success";
    state.filters.data = response;
    state.filters.error = null;

  },
  [constants.GET_VEHICLE_VALUATION_FILTERS_ERROR]: (state, error) => {
    state.filters.status = "error";
    state.filters.data = null;
    state.filters.error = error
  },
  [constants.CLEAR_VEHICLE_VALUATION_MODELS]: (state) => {
    state.filters.models.status = null;
    state.filters.models.data = [];
    state.filters.models.error = null
  },
  [constants.GET_VEHICLE_VALUATION_FILTERS_MODELS]: (state) => {
    state.filters.models.status = "fetching";
    state.filters.models.data = [];
    state.filters.models.error = null;
  },
  [constants.GET_VEHICLE_VALUATION_FILTERS_MODELS_SUCCESS]: (state, response) => {
    state.filters.models.status = "success";
    state.filters.models.data = response;
    state.filters.models.error = null
  },
  [constants.GET_VEHICLE_VALUATION_FILTERS_MODELS_ERROR]: (state, error) => {
    state.filters.models.status = "error";
    state.filters.models.data = [];
    state.filters.models.error = error
  },
  [constants.GET_GENERATE_VALUATION]: (state) => {
    state.vehicleValuationDetail.status = "fetching";
    state.vehicleValuationDetail.data = null;
    state.vehicleValuationDetail.error = null;
  },
  [constants.GET_GENERATE_VALUATION_SUCCESS]: (state, response) => {
    state.vehicleValuationDetail.status = "success";
    state.vehicleValuationDetail.data = response;
    state.vehicleValuationDetail.error = null
  },
  [constants.GET_GENERATE_VALUATION_ERROR]: (state, error) => {
    state.vehicleValuationDetail.status = "error";
    state.vehicleValuationDetail.data = null;
    state.vehicleValuationDetail.error = error
  },
  [constants.SET_VEHICLE_VALUATION_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.vehicles.filters = {
        make: null,
        model: null,
        year: null,
        status: null,
        subStatus: null,
        vin: null,
        region: null,
        page: 1,
        id: null,
      };
    }
    if (filters.fields) {
      state.vehicleValuations.filters = {
        ...state.vehicleValuations.filters,
        ...filters.fields,
      };
    }
  },

  [constants.CLEAR_VEHICLE_VALUATION]: (state) => {
    state.vehicleValuations.status = null;
    state.vehicleValuations.data = null;
    state.vehicleValuations.error = null;
  },
  [constants.GET_VEHICLE_VALUATION_XLS]: (state) => {
    state.vehicleValuationXLS.status = "fetching";
    state.vehicleValuationXLS.data = null;
    state.vehicleValuationXLS.error = null;
  },
  [constants.GET_VEHICLE_VALUATION_XLS_SUCCESS]: (state, response) => {
    state.vehicleValuationXLS.status = "success";
    state.vehicleValuationXLS.data = response;
    state.vehicleValuationXLS.error = null
  },
  [constants.GET_VEHICLE_VALUATION_XLS_ERROR]: (state, error) => {
    state.vehicleValuationXLS.status = "error";
    state.vehicleValuationXLS.data = null;
    state.vehicleValuationXLS.error = error
  },


};



export default {
  state: getDefaultState(),
  actions,
  mutations,
};
