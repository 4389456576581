<template>
  <header
    class="border-bottom border-primary border-top-0 border-start-0 border-end-0 mb-0"
    style="border-left: 0 !important; border-right: 0 !important"
  >
    <img
      v-if="showPixel"
      height="1"
      width="1"
      style="border-style: none"
      alt=""
      src="https://insight.adsrvr.org/track/pxl/?adv=pzdunu8&ct=0:xtknu9t&fmt=3"
    />
    <b-navbar class="pt-3 text-grey" toggleable="lg" variant="white">
      <b-navbar-brand class="pe-none">
        <img
          src="@/assets/img/logos/proleasing-logo.svg"
          width="100%"
          height="auto"
        />
      </b-navbar-brand>
      <Notifications />
      <b-nav-item
        v-if="isMobile"
        :active="$route.name && $route.name.includes('inventory.vehicles')"
        :to="{ name: 'inventory.vehicles' }"
      >
        <b-button variant="primary" class="btn btn-lg">
          <span class="text-white"> Buy now! </span>
        </b-button>
      </b-nav-item>

      <!--       
         style=""
          v-if="witdhResponsive === '100%'"
 -->
      <a
        v-if="false"
        href="tel: +1 514 333 0070"
        id="tooltip-canam-contact-number-mobile"
        style="margin-left: 80px"
      >
        <img
          src="@/assets/img/icons/phone.png"
          class="img-responsive"
          style="max-width: 30px"
        />
      </a>

      <b-tooltip target="tooltip-canam-contact-number-mobile" triggers="hover">
        <a href="tel: +1 514 333 0070" style="color: white">
          <b>Contact Phone Number</b>
          <h5 class="mt-2">+1 514 333 0070</h5>
          Tap to call
        </a>
      </b-tooltip>

      <b-nav class="w-100 d-none d-lg-inline-flex" pills align="end">
        <template v-if="userIsClient || userIsBuyer">
          <b-nav-item
            v-for="link in filterLinks"
            v-bind:key="link.routeName"
            :disabled="link.isDisabled || !userHasCompany"
            :active="$route.name && $route.name.includes(link.includesActive)"
            :to="{ name: link.routeName }"
          >
            <b-button variant="primary btn-lg mt-0">
              <span class="text-white">
                {{ link.display }}
              </span>
            </b-button>
            <!--             <span class="text-grey">
              {{ link.display }}
            </span> -->
          </b-nav-item>
        </template>
        <template v-else>
          <b-nav-item
            v-for="link in filterLinks"
            v-bind:key="link.routeName"
            :disabled="link.isDisabled"
            :active="$route.name && $route.name.includes(link.includesActive)"
            :to="{ name: link.routeName }"
            class=""
          >
            <div v-if="link.children">
              <b-nav-item-dropdown
                id="my-nav-dropdown"
                :text="link.display"
                toggle-class="nav-link-custom"
                right
                class="text-white"
              >
                <b-dropdown-item
                  :to="{ name: item.routeName }"
                  v-for="item in link.children"
                  :key="item.id"
                  >{{ item.display }}</b-dropdown-item
                >
              </b-nav-item-dropdown>
            </div>
            <div v-else class="mt-2">
              {{ link.display }}
            </div>
          </b-nav-item>
        </template>

        <div v-if="!this.profile">
          <!-- Login button -->
          <b-button
            variant="none"
            class="btn btn-success btn-lg ml-3"
            @click="login"
          >
            <span class="mr-3 ml-3">
              <b-icon icon="box-arrow-in-right" variant="white" class="mr-1" />
              {{ $t("login") }}
            </span>
          </b-button>

          <b-button
            variant="none"
            class="btn btn-outline-primary btn-lg ml-3"
            @click="goToRegister()"
          >
            <span class="mr-3 ml-3"> {{ $t("register") }} </span>
          </b-button>
        </div>
        <!-- Logout button -->
        <div v-else>
          <b-button
            variant="none"
            class="btn btn-outline-danger btn-lg ml-3"
            @click="logout"
          >
            <span class="">{{ $t("logout") }}</span>
          </b-button>
        </div>

        <!-- Contact phone number -->
        <a
          href="tel: +1 514 333 0070"
          id="tooltip-canam-contact-number-desktop"
          @click="waitToPixel()"
        >
          <img
            src="@/assets/img/icons/phone.png"
            class="img-responsive ml-3 mt-2"
            style="max-width: 30px"
          />
        </a>
        <b-tooltip
          target="tooltip-canam-contact-number-desktop"
          triggers="hover"
        >
          <b>{{ $t("contactPhoneNumber") }}</b>
          <br />
          <a href="tel: +1 514 333 0070" style="color: white">
            +1 514 333 0070
          </a>
        </b-tooltip>
        <b-button
          variant="link"
          @click="changeLang()"
          class="btn-lg ml-1 text-decoration-none"
        >
          {{ $root.$i18n.locale.toUpperCase() == "FR" ? "EN" : "FR" }}
        </b-button>
      </b-nav>
      <b-navbar-toggle target="none" v-b-toggle.sidebar-menu> </b-navbar-toggle>
    </b-navbar>
    <b-sidebar
      id="sidebar-menu"
      :width="widthSidebar"
      title=""
      bg-variant="white"
      text-variant="black"
    >
      <template v-slot:title v-if="profile">
        <img
          src="@/assets/img/logos/proleasing-logo.svg"
          width="25%"
          height="auto"
          class="image-responsive"
        />
      </template>

      <b-nav vertical class="text-center text-light">
        <div v-if="userIsClient || userIsBuyer">
          <b-nav-item
            v-for="link in filterLinks"
            v-bind:key="link.routeName"
            :disabled="link.isDisabled || companyIsPending"
            :active="$route.name && $route.name.includes(link.includesActive)"
            :to="{ name: link.routeName }"
          >
            <hr />
            <b-row>
              <b-col class="p-4">
                <span class="text-primary">
                  {{ link.display }}
                </span>
              </b-col>
            </b-row>
          </b-nav-item>
        </div>
        <div v-else>
          <b-nav-item
            v-for="link in filterLinks"
            v-bind:key="link.routeName"
            :disabled="link.isDisabled"
            :active="$route.name && $route.name.includes(link.includesActive)"
            :to="{ name: link.routeName }"
          >
            <hr />
            <b-row>
              <b-col class="p-4">
                <span class="text-primary">
                  {{ link.display }}
                </span>
              </b-col>
            </b-row>
          </b-nav-item>
        </div>
      </b-nav>

      <b-container v-if="!profile" fluid class="text-center mt-2 p-4">
        <LoginForm />
      </b-container>

      <template v-slot:footer>
        <div
          v-if="profile"
          class="d-flex bg-white text-light justify-content-between align-items-center px-3 py-2"
        >
          <!-- Logout button -->
          <b-button variant="outline-danger" size="lg" block @click="logout">
            <span class="">{{ $t("logout") }}</span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <Login />
  </header>
</template>

<script>
import { mapState } from "vuex";
import appRoles from "../helpers/appRoles";
import Login from "@/modules/auth/login/LoginModal";
import LoginForm from "@/modules/auth/login/LoginForm";
import { AUTH_LOGOUT } from "@/modules/auth/login/actions";
import { generalMixin } from "@/modules/mixin.js";
import Notifications from "@/modules/shared/Notifications.vue";
export default {
  components: { Login, LoginForm, Notifications },
  mixins: [generalMixin],
  data() {
    return {
      unAuthenticatedUsersLink: [
        /*         {
          display: this.$t("viewInventoryBuyNow") + "!",
          routeName: "inventory.vehicles",
          includesActive: "View Inventory",
        }, */
      ],
      superAdminLinks: [
        {
          display: this.$t("viewInventory"),
          routeName: "admin.vehicles.list",
          includesActive: "View Inventory",
        },

        {
          display: this.$t("users"),
          routeName: "admin.assign-company",
          includesActive: "Users",
        },
      ],
      adminLinks: [
        {
          display: this.$t("viewInventory"),
          routeName: "admin.vehicles.list",
          includesActive: "View Inventory",
        },

        {
          display: this.$t("users"),
          routeName: "admin.assign-company",
          /*           includesActive: "admin.assign-company",
           */ children: [
            {
              display: "Buyers",
              routeName: "admin.assign-company",
            },

            {
              display: "Internal users",
              routeName: "admin.agent",
            },
          ],
        },
        {
          display: "Management",
          routeName: "admin.fx-calendar",
          children: [
            {
              display: "Fx calendar",
              routeName: "admin.fx-calendar",
            },
            {
              display: "Vehicle valuation",
              routeName: "admin.vehicle-valuation",
            },
          ],
        },

        /*     {
          display: "Agents",
          routeName: "admin.assign-company",
          includesActive: "Agents",
        }, */
      ],
      companyLinks: [],
      clientLinks: [
        {
          display: this.$t("viewInventory"),
          routeName: "client.car-list",
          includesActive: "View Inventory",
        },
        {
          display: this.$t("users"),
          routeName: "buyer.users",
          includesActive: "Users",
        },
      ],

      buyerLinks: [
        {
          display: this.$t("viewInventory"),
          routeName: "buyer.car-list",
          includesActive: "View Inventory",
        },
      ],
      agentLinks: [
        {
          display: this.$t("viewInventory"),
          routeName: "agent.vehicles.list",
          includesActive: "View Inventory",
        },
      ],
      reviewerLinks: [
        {
          display: this.$t("users"),
          routeName: "admin.assign-company",
          includesActive: "Users",
        },
      ],
      trackingLinks: [],
      showPixel: false,
    };
  },
  computed: {
    widthSidebar() {
      return this.$vssWidth > 1280
        ? "25%"
        : this.$vssWidth <= 576
        ? "100%"
        : "50%";
    },
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
    }),
    filterLinks() {
      let links = [];
      if (this.profile && this.profile.user.roles.length) {
        const [
          superAdmin,
          admin,
          buyer,
          client,
          agent,
          reviewer,
          wholesale,
          retail,
        ] = appRoles;

        this.profile.user.roles.forEach((value, index) => {
          switch (this.profile.user.roles[index].name) {
            case superAdmin.authority:
              links.push(...this.superAdminLinks); // Add super admin links
              break;
            case admin.authority:
              links.push(...this.adminLinks);
              break;
            case buyer.authority:
              links.push(...this.buyerLinks);
              break;
            case client.authority:
              links.push(...this.clientLinks); // Add buyer links
              break;
            case agent.authority:
              links.push(...this.agentLinks); // Add agent links
              break;
            case reviewer.authority:
              links.push(...this.reviewerLinks);
              break;
            case wholesale.authority:
              links.push(...this.buyerLinks);
              break;
            case retail.authority:
              links.push(...this.buyerLinks);
              break;
            default:
              links.push();
          }
        });
      } else {
        links = [...this.unAuthenticatedUsersLink];
      }
      return links;
    },
    isMobile() {
      return this.$vssWidth <= 576 ? true : false;
    },
  },
  methods: {
    login() {
      this.$bvModal.show("login-modal");
    },
    logout() {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$root.$bvToast.toast("Error to logout", {
            title: "Error",
            variant: "danger",
          });
        });
    },
    goToRegister() {
      if (this.$route.name !== "buyer.create")
        this.$router.push({ name: "buyer.create" });
    },
    waitToPixel() {
      this.showPixel = true;
      setTimeout(() => {
        this.showPixel = false;
      }, 1000);
    },
    changeLang() {
      if (this.$root.$i18n.locale == "fr") {
        this.$root.$i18n.locale = "en";
        window.localStorage.setItem("language", "en");
      } else {
        this.$root.$i18n.locale = "fr";
        window.localStorage.setItem("language", "fr");
      }
      location.reload();
    },
  },
};
</script>

<style scoped>
.icon-text {
  font-size: 25px;
}
a:hover {
  text-decoration: none;
  color: grey;
}
.nav-item-color {
  color: grey;
}
.nav-link {
  padding-top: 0;
}

div.ui-menu li {
  list-style: none;
  background-image: none;
  background-repeat: none;
  background-position: 0;
}
ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.router-link-active {
  text-align: center;
}
</style>
